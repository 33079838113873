import {
    ExploreAndSearchResponse,
    FavoriteFilter,
    GetWishListsResult
} from '../../../typings/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Wishlist } from '../models/wishlist.model';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class WishlistService {
    constructor(private http: HttpClient) {}
    baseUrlFavoriteV2 = `${environment.GATE_URL}/v2/favorites`;

    /**
     *
     *
     * Get logged-in user's wish list
     *
     *
     *
     * @return
     */
    getWishList(): Observable<GetWishListsResult> {
        return this.http.get<GetWishListsResult>(
            `${environment.GATE_URL}/v2/wish-list/get-all`
        );
    }

    getWishListDetails(
        wishListIds: FavoriteFilter['query_filter']['filter']
    ): Observable<ExploreAndSearchResponse> {
        const filter: FavoriteFilter = {
            query_filter: { filter: wishListIds }
        };
        return this.http.post<ExploreAndSearchResponse>(
            `${environment.GATE_URL_2}/se/favorite/v2`,
            filter
        );
    }

    /**
     *
     *
     * Toggle a treks wishlist status for current logged-in user
     *
     *
     *
     * @param isInWishlist {Boolean} is trek currently in wishlist
     * @param trekId {Number} trek identifier
     * @return
     */
    toggleTrekWishlistStatus(
        isInWishlist: boolean,
        trekId: string
    ): Observable<{}> {
        const url = `${this.baseUrlFavoriteV2}/trek/${trekId}`;
        return this.http.request(isInWishlist ? 'delete' : 'post', url);
    }

    /**
     * Toggle a site's wishlist status for current logged-in user
     *
     * @param isInWishlist {Boolean} is site not currently in wishlist
     * @param site_id {Number} site identifier
     */
    toggleSiteWishlistStatus(
        // no-underscore-dangle, id-blacklist, id-match
        isInWishlist: boolean,
        site_id: number | string
    ): Observable<{}> {
        const url = `${this.baseUrlFavoriteV2}/site/${site_id}`;
        return this.http.request(isInWishlist ? 'delete' : 'post', url);
    }

    /**
     *
     *
     * Transform a {@link GetWishListsResult} to a {@link Wishlist} object
     *
     *
     *
     * @param wishlistResult
     * @return
     */
    toWishlist(wishlistResult?: GetWishListsResult): Wishlist {
        return {
            treks: wishlistResult?.trekIds || [],
            sites: wishlistResult?.siteIds || [],
            itinerary: wishlistResult?.itineraryIds || []
        };
    }

    toggleItineraryWishlistStatus(
        isInWishlist: boolean,
        itineraryId: string
    ): Observable<{}> {
        const url = `${this.baseUrlFavoriteV2}/itinerary/${itineraryId}`;
        return this.http.request(isInWishlist ? 'delete' : 'post', url);
    }
}
